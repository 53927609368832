import { render, staticRenderFns } from "./dyzxzj.vue?vue&type=template&id=3ca7e868&scoped=true&"
import script from "./dyzxzj.vue?vue&type=script&lang=js&"
export * from "./dyzxzj.vue?vue&type=script&lang=js&"
import style0 from "./dyzxzj.vue?vue&type=style&index=0&id=3ca7e868&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ca7e868",
  null
  
)

export default component.exports